import { folderWorkplaceFrag, serviceMedicalProcedureGridItemFrag } from './service.types';
import { serviceUserFrag } from './user.types';
import { folderGridItemFrag, folderWorkgroupGridItemFrag } from './folder.types';

export const schedulerVoucherPrescribedMedicalProceduresFrag = `
    id
    code
    voucherId
    voucher {
        id
        type
        sequenceNumber
        doctor {
            ${serviceUserFrag}
        }
    }
    service {
        id
        name
    }
    serviceMedicalProcedureId
    serviceMedicalProcedure {
        ${serviceMedicalProcedureGridItemFrag}
    }
    canBeBilled
    canBeBilledWeekend
    canBeBilledNight
    continuousOperation
    courseDescription
    recordTemplate
    usualNumberOfApplications
    frequency
`;

export const personAppointmentFrag = `
    id
    name
    surname
    sex
    titleBeforeName
    titleAfterName
    birthdate        
`;

export const schedulerAppointmentFrag = `
    id
    startTime
    endTime
    location
    isAllDay
    isBlock
    isReadonly
    recurrenceRule
    recurrenceException
    recurrenceId
    startTimezone
    endTimezone
    description
    followingId
    systolicPressure
    diastolicPressure
    pulse
    breath
    glycemia
    spo2
    userId
    personId
    person {
        ${personAppointmentFrag}
    }
    medications {
        id
        code
        dose
        folderMedicationId
        serviceId
        medication {
            name
            code
            strength
        }
        service {
            id
            name
            timeRanges {
                order
                active
                from
                to
            }
        }
    }
    procedures {
        id
        code
        applications
        serviceMedicalProcedureId
        voucherPrescribedMedicalProcedureId
        serviceId
        service {
            name
        }
        voucherPrescribedMedicalProcedure {
            ${schedulerVoucherPrescribedMedicalProceduresFrag}
        }
    }
    services {
        id
        fullFilled
        date
        workId
        services {
            appointmentServiceId
            serviceId
            folder {
                ${folderGridItemFrag}
                workplaces {
                    ${folderWorkplaceFrag}
                }
                workgroup {
                    ${folderWorkgroupGridItemFrag}
                }
            }
            vouchers {
                voucherId
                workId
            }
        }
    }
`;
