import { GridInput } from '@it2go/data-grid';
import { OkObject } from '@it2go/types';
import { filterFrag } from '../modules/content/service/types/filter.types';
import { GqlService } from '../modules/content/service/gql.service';
import { Injectable } from '@angular/core';
import { FormGridObject } from '@libs/types/src/services/object-type/form/form.object';
import { folderFormFrag, formFrag } from './types/form.types';
import { FolderFormGridObject } from '@libs/types/src/services/object-type/form/folder-form.object';
import {
    FolderFormDataAssignWorkInput,
} from '@libs/types/src/services/input-type/forms/folder-form-data.assign-work-input';

@Injectable()
export class FormGqlService extends GqlService {

    public getFormsV2(input: GridInput | null) {
        return this.query<FormGridObject>(`
                query($input: GridInput) {
                    formV2 {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${formFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderFormsV2(input: GridInput | null) {
        return this.query<FolderFormGridObject>(`
                query($input: GridInput) {
                    folderFormV2 {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${folderFormFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public assignFolderFormDataAssignWork(input: FolderFormDataAssignWorkInput) {
        return this.mutate<OkObject>(`
                mutation($input: FolderFormDataAssignWorkInput!) {
                    folderFormDataV2 {
                        assignWork(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
