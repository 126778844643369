import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActionButtonsComponent } from '../action-buttons/action-buttons.component';
import { BaseDialogActions } from '../../../interfaces/base-dialog-actions.types';
import { SubscriptionComponent } from '../../../modules/shared/helpers/subscription.component';

@Component({
    selector: 'app-base-dialog',
    templateUrl: 'base-dialog.component.html',
    imports: [TranslateModule, ActionButtonsComponent],
    standalone: true,
})
/**
 * custom footer content must be wrapped with <div #footer class="p-dialog-footer">...</div>, see examples -> dynamic dialogs section
 */
export class BaseDialogComponent extends SubscriptionComponent implements BaseDialogActions {

    protected dialogRef = inject(DynamicDialogRef);
    protected dialogConfig = inject(DynamicDialogConfig);

    @ViewChild('footer', { static: true }) footerInstance!: ElementRef<HTMLDivElement>;

    public save(_event: Event): void | Promise<void> {
    }

}
