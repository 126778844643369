import { isAllowed } from '../modules/shared/shared.global-service';
import { WorkTypeEnum } from '@libs/types/src/common/enum/work-type.enum';

export function allowedWorkTypesByExpertise(expertiseCodes: string[], withServiceRecord: boolean = true): WorkTypeEnum[] {
    const ret: WorkTypeEnum[] = [];

    if (isAllowed['work.create']()) {
        if (expertiseCodes.find((code) => ['902', '925', '926'].includes(code))) {
            ret.push(
                WorkTypeEnum.VISIT,
                WorkTypeEnum.UNREALIZED_VISIT,
            );
        }
        if (expertiseCodes.find((code) => ['926'].includes(code))) {
            ret.push(
                WorkTypeEnum.EXTRAMURAL_CARE,
                WorkTypeEnum.RESIDUARY_CARE,
            );
        }
        if (expertiseCodes.find((code) => ['913'].includes(code))) {
            ret.push(
                WorkTypeEnum.INTERVENTION,
            );
        }
    }
    if (isAllowed['specialistWork.create']()) {
        if (expertiseCodes.find((code) => ['926'].includes(code))) {
            ret.push(
                WorkTypeEnum.SPECIALIST_RECORD,
            );
        }
    }
    if (isAllowed['doctorWork.create']()) {
        if (expertiseCodes.find((code) => ['925', '926', '720'].includes(code))) {
            ret.push(
                WorkTypeEnum.DOCTOR_RECORD,
            );
        }
    }
    if (isAllowed['operationalWork.create']() && withServiceRecord) {
        ret.push(WorkTypeEnum.SERVICE_RECORD);
    }

    return ret;
}