import { computed, Injectable, Signal, signal } from '@angular/core';
import { EmploymentGridItemObject } from '@libs/types/src/user/object-type/employment-grid.object';
import { AuthenticatedUserObject } from '@it2go/types';

@Injectable({
    providedIn: 'root',
})
export class LoggedUserGlobalStore {

    public currentUserId = signal<string | null>(null);
    public currentUser = signal<AuthenticatedUserObject | null>(null);

    public employments = signal<EmploymentGridItemObject[]>([]);

    public serviceIds() {
        return computed(() => {
            return [...new Set(this.employments().map(x => x.serviceId))];
        });
    }

    public getEmploymentsByServiceId(id: Signal<number>) {
        return computed(() => {
            return this.employments().find((it: EmploymentGridItemObject) => it.serviceId === id());
        });
    }

    public showMenuItem(settingsKey: string) {
        return computed(() => {
            return this.employments().some((it: EmploymentGridItemObject) => {
                const serviceSettings = it.serviceSettings?.find((x) => x.key === settingsKey);
                return JSON.parse(serviceSettings?.data || '{}').useCard ?? true;
            })
        })
    }

}
