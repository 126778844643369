import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import {
    OkObject,
    WorkplaceAddressCreateInput,
    WorkplaceAddressDeleteInput,
    WorkplaceAddressGridObject,
    WorkplaceAddressUpdateInput,
    WorkplaceGridObject,
    WorkplaceIcpCreateInput,
    WorkplaceIcpGridObject,
    WorkplaceIcpUpdateInput,
    WorkplaceObject,
    WorkplaceResponsiblePersonCreateInput,
    WorkplaceResponsiblePersonGridObject,
    WorkplaceResponsiblePersonUpdateInput,
} from '@it2go/types';
import { GqlService } from '../modules/content/service/gql.service';
import { WorkplaceCreateInput } from '@libs/types/src/services/input-type/services/workplace-create.input';
import {
    folderWorkplaceGridItemFrag,
    workplaceAddressFrag,
    workplaceFrag,
    workplaceGridItemFrag,
    workplaceIcpFrag,
    workplaceResponsiblePersonFrag,
} from './types/service.types';
import { WorkplaceUpdateInput } from '@libs/types/src/services/input-type/services/workplace-update.input';
import { WorkplaceDeleteInput } from '@libs/types/src/services/input-type/services/workplace-delete.input';
import { filterFrag } from './types/filter.types';
import {
    WorkplaceUnassignToFolderInput,
} from '@libs/types/src/services/input-type/services/workplace-unassign-to-folder.input';
import {
    WorkplaceAssignToFolderInput,
} from '@libs/types/src/services/input-type/services/workplace-assign-to-folder.input';
import { FolderWorkplaceUpdateInput } from '@libs/types/src/services/input-type/services/folder-workplace-update.input';
import { FolderWorkplaceGridObject } from '@libs/types/src/services/object-type/folder-workplace.object';
import { FolderWorkplaceCreateInput } from '@libs/types/src/services/input-type/services/folder-workplace.create-input';
import { IdInput } from '@libs/types/src/common/input-type/id.input';

@Injectable()
export class WorkplaceService extends GqlService {

    public createWorkplace(input: WorkplaceCreateInput) {
        return this.mutate<WorkplaceObject>(
            `
                mutation($input: WorkplaceCreateInput!) {
                    workplace {
                        create(input: $input) {
                            ${workplaceFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getWorkplaces(input: GridInput | null = null) {
        return this.query<WorkplaceGridObject>(
            `
                query($input: GridInput) {
                    workplace {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${workplaceGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderWorkplaces(input: GridInput | null = null) {
        return this.query<FolderWorkplaceGridObject>(
            `
                query($input: GridInput) {
                    workplace {
                        filterFolderWorkplace(input: $input) {
                            ${filterFrag}
                            items {
                                ${folderWorkplaceGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateWorkplace(input: WorkplaceUpdateInput) {
        return this.mutate<WorkplaceObject>(
            `
                mutation($input: WorkplaceUpdateInput!) {
                    workplace {
                        update(input: $input) {
                            ${workplaceFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteWorkplace(input: WorkplaceDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceDeleteInput!) {
                    workplace {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public assignWorkplaceToFolder(input: WorkplaceAssignToFolderInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceAssignToFolderInput!) {
                    workplace {
                        assignToFolder(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public unassignWorkplaceFromFolder(input: WorkplaceUnassignToFolderInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceUnassignToFolderInput!) {
                    workplace {
                        unassignFromFolder(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createFolderWorkplace(input: FolderWorkplaceCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderWorkplaceCreateInput!) {
                    workplace {
                        createFolderWorkplace(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateFolderWorkplace(input: FolderWorkplaceUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderWorkplaceUpdateInput!) {
                    workplace {
                        updateFolderWorkplace(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getWorkplaceAddresses(input: GridInput | null = null) {
        return this.query<WorkplaceAddressGridObject>(
            `
                query($input: GridInput) {
                    workplaceAddress {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${workplaceAddressFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createWorkplaceAddress(input: WorkplaceAddressCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceAddressCreateInput!) {
                    workplaceAddress {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateWorkplaceAddress(input: WorkplaceAddressUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceAddressUpdateInput!) {
                    workplaceAddress {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteWorkplaceAddress(input: WorkplaceAddressDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceAddressDeleteInput!) {
                    workplaceAddress {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getWorkplaceResponsiblePersons(input: GridInput | null = null) {
        return this.query<WorkplaceResponsiblePersonGridObject>(
            `
                query($input: GridInput) {
                    workplaceResponsiblePerson {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${workplaceResponsiblePersonFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createWorkplaceResponsiblePerson(input: WorkplaceResponsiblePersonCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceResponsiblePersonCreateInput!) {
                    workplaceResponsiblePerson {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateWorkplaceResponsiblePerson(input: WorkplaceResponsiblePersonUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceResponsiblePersonUpdateInput!) {
                    workplaceResponsiblePerson {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteWorkplaceResponsiblePerson(input: IdInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    workplaceResponsiblePerson {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getWorkplaceIcps(input: GridInput | null = null) {
        return this.query<WorkplaceIcpGridObject>(
            `
                query($input: GridInput) {
                    workplaceIcp {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${workplaceIcpFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createWorkplaceIcp(input: WorkplaceIcpCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceIcpCreateInput!) {
                    workplaceIcp {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateWorkplaceIcp(input: WorkplaceIcpUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceIcpUpdateInput!) {
                    workplaceIcp {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteWorkplaceIcp(input: IdInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    workplaceIcp {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
