import {
    Component,
    computed,
    contentChild,
    effect,
    inject,
    input,
    signal,
    TemplateRef,
    untracked,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollStore } from '../../../../modules/shared/store/common/infinite-scroll.store';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import config from '../../../../config/config';
import { FieldErrorComponent } from '../../../../modules/shared/components/field-error/field-error.component';
import { FloatLabelModule } from 'primeng/floatlabel';
import { NgTemplateOutlet } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { SelectInputStore } from './select-input.store';
import { BaseInputComponent } from '../base-input.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { fb } from '../../../../modules/shared/shared.global-service';

@Component({
    selector: 'app-select-input',
    templateUrl: 'select-input.component.html',
    styleUrl: 'select-input.component.scss',
    standalone: true,
    providers: [
        SelectInputStore,
    ],
    imports: [
        FieldErrorComponent,
        FloatLabelModule,
        FormsModule,
        NgTemplateOutlet,
        PrimeTemplate,
        TranslateModule,
        ReactiveFormsModule,
        DropdownModule,
    ],
})
export class SelectInputComponent extends BaseInputComponent<string>/* implements OnDestroy*/ {
    protected readonly store = inject(SelectInputStore);

    public override id = input<string>('select');
    public override form = input<FormGroup>(fb.group({ [this.id()]: fb.control(null) }));

    public labelKey = input<string>('name');
    public valueKey = input<string>('value');
    public dataKey = input<string | null>(null);
    public idField = input<string>('id');

    public appendTo = input<string>('body');

    public showClear = input<boolean>(true);
    public editable = input<boolean>(false);
    public filterBy = input<string | null>(null);

    public staticOptions = input<any[]>([]);

    // TODO group

    public infiniteScroll = input<InfiniteScrollStore<any> | null>(null);

    public itemTemplate = contentChild<TemplateRef<any> | null>('item');
    public selectedItemTemplate = contentChild<TemplateRef<any> | null>('selectedItem');

    protected searchSubject = new Subject<string>();
    protected searchChange = toSignal<string>(this.searchSubject.pipe(debounceTime(config.debounceTime)));

    protected translatedStaticOptions = computed(() => {
        return this.staticOptions().map((it) => {
            return {
                [this.valueKey()]: it[this.valueKey()],
                [this.labelKey()]: this.translate.instant(it[this.labelKey()]),
            };
        });
    });

    private initialized = signal(false);

    constructor() {
        super();

        this.fetchItems();

        effect(() => {
            const term = this.searchChange();
            this.fetchItems(term);
        });

        effect(() => {
            const change = this.formValueChange();
            if (change && !this.initialized()) {
                this.infiniteScroll()?.prefetchItems([[{
                    column: this.idField(),
                    operator: 'EQ',
                    value: [change],
                }]], this.idField());
                untracked(() => {
                    this.initialized.set(true);
                });
            }
        });
    }

    protected filter(search: string): void {
        this.searchSubject.next(search);
    }

    protected fetchItems(search: string | null = null): void {
        const scroll = this.infiniteScroll();
        if (!scroll) return;

        scroll.changeSearch(search);
    }

}
