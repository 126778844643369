import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[appInfiniteScroll]',
    standalone: true,
})
export class InfiniteScrollDirective implements OnInit {
    @Output() nearEnd: EventEmitter<void> = new EventEmitter<void>();

    @Input() threshold = 150; // pocet px od konce, kdy se ma zavolat nearEnd

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        if (this.el.nativeElement.tagName === 'P-SCROLLPANEL') {
            this.el.nativeElement.getElementsByClassName('p-scrollpanel-content')?.[0]?.addEventListener('scroll', (event: Event) => {
                this.onScroll(event);
            });
        } else {
            this.el.nativeElement.addEventListener('scroll', (event: Event) => {
                this.onScroll(event);
            });
        }
    }

    private onScroll(event: Event) {
        const target = event.target as HTMLElement;

        // vyska celeho obsahu elementu
        const heightOfWholeElement = target.scrollHeight;

        // vyska viditelneho obsahu elementu
        const heightOfSeenPart = target.clientHeight;

        // kam je zascrollovano
        const currentScrolledY = target.scrollTop;

        // zbyvajici vyska pro scrollovani
        const remainingHeight = heightOfWholeElement - heightOfSeenPart - currentScrolledY;

        if (remainingHeight < this.threshold) {
            this.nearEnd.emit();
        }
    }
}
