import { Component, computed, inject, input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SeverityType } from '../button/button.types';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-tag',
    templateUrl: 'tag.component.html',
    styleUrl: 'tag.component.scss',
    standalone: true,
    imports: [
        TagModule,
        TranslateModule,
        TooltipModule,
        NgIf,
    ],
})
export class TagComponent {

    public translate = inject(TranslateService);

    public label = input('');
    public severity = input<SeverityType>('primary');
    public rounded = input(false);
    public maxLength = input<number | null>(null);

    protected shortLabel = computed<string>(() => {
        if (!this.label()?.length) return '';
        const txt = this.translate.instant(this.label());
        const len = this.maxLength();
        if (len && len < txt.length) {
            this.elipsed = true;
            return txt.substring(0, len - 3).trimEnd() + '...';
        }

        return txt;
    });
    protected elipsed = false;



}
