import { Component, input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-drop-down-menu',
    templateUrl: 'drop-down-menu.component.html',
    styleUrl: 'drop-down-menu.component.scss',
    standalone: true,
    imports: [
        MenuModule,
        NgTemplateOutlet,
        NgClass,
    ],
})
export class DropDownMenuComponent {

    public target = input<any>();
    public items = input<MenuItem[]>([]);
    public appendTo = input<string>('body');
    public disabled = input(false);

}
