<div [formGroup]="form()" class="flex align-items-center gap-small">
    <p-checkbox [readonly]="readonly()" [formControlName]="id()" [binary]="true" [inputId]="'check-' + id()"
        (onChange)="check($event.checked)"></p-checkbox>
    @if (showLabel()) {
        <label [for]="'check-' + id()">
            @if (labelBoldPrefix()) {
                <span class="font-bold">{{ labelBoldPrefix()! | translate }} - </span>
            }
            {{ lbl() | translate }}
        </label>
    }
</div>
