import { Component, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';
import { FieldErrorComponent } from '../../../../modules/shared/components/field-error/field-error.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { InputTextModule } from 'primeng/inputtext';
import { IconComponent } from '../../icon/icon.component';
import { BaseInputComponent } from '../base-input.component';
import { fb } from '../../../../modules/shared/shared.global-service';

export const textInputImports = [
    ReactiveFormsModule,
    TranslateModule,
    NgIf,
    FieldErrorComponent,
    NgClass,
    SvgIconComponent,
    InputTextModule,
    IconComponent,
];

@Component({
    selector: 'app-text-input',
    templateUrl: 'text-input.component.html',
    styleUrl: 'text-input.component.scss',
    standalone: true,
    imports: textInputImports,
})
export class TextInputComponent extends BaseInputComponent<string> {
    public override id = input<string>('text');
    public override form = input<FormGroup>(fb.group({ [this.id()]: fb.control(null) }));

    public icon = input<string | null>(null);
    public placeholder = input<string>('');
    public svgIcon = input<string | null>(null);

    public clear = input<boolean>(false);

    protected type = 'text';

    protected clearInput(): void {
        this.form().get(this.id())?.reset();
    }

}
