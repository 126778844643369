import { enumToOptions } from '@libs/types/src/common/enum/util';

export enum WorkTypeEnum {
    VISIT = 'visit',
    UNREALIZED_VISIT = 'unrealizedVisit',
    INTERVENTION = 'intervention',
    EXTRAMURAL_CARE = 'extramuralCare',
    RESIDUARY_CARE = 'residuaryCare',
    SERVICE_RECORD = 'serviceRecord',
    DOCTOR_RECORD = 'doctorRecord',
    SPECIALIST_RECORD = 'specialistRecord',
}

export const workTypeEnumOptions = enumToOptions(WorkTypeEnum, 'workData.common.enum.type.');