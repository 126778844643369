import { Component, inject, input } from '@angular/core';
import { ConfirmationType } from './button.types';
import { Button } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { PrimeTemplate } from 'primeng/api';
import {
    ConfirmationWrapperService,
    DEFAULT_CONFIRMATION_QUESTION_INPUT,
} from '../../../services/wrappers/confirmation-wrapper.service';
import { Confirmation } from 'primeng/api/confirmation';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SaveButtonComponent } from './save-button.component';

@Component({
    selector: 'app-confirm-button',
    templateUrl: 'button.component.html',
    imports: [Button, TranslateModule, SvgIconComponent, PrimeTemplate, ConfirmPopupModule, ConfirmDialogModule],
    providers: [ConfirmationWrapperService],
    standalone: true,
})
export class ConfirmButtonComponent extends SaveButtonComponent {
    private readonly confirmationService = inject(ConfirmationWrapperService);

    confirmationType = input<ConfirmationType>('popup');
    confirmationMessageConfig = input<Confirmation>(DEFAULT_CONFIRMATION_QUESTION_INPUT);

    override async onClick(event: Event) {
        switch (this.confirmationType()) {
            case 'popup':
                if (await this.confirmationService.question(event.target as EventTarget, 'confirmPopup', this.confirmationMessageConfig())) {
                    this.clickEvent.emit(event);
                }
                break;
            case 'dialog':
                if (await this.confirmationService.question(event.target as EventTarget, 'confirmDialog', this.confirmationMessageConfig())) {
                    this.clickEvent.emit(event);
                }
                break;
            default:
                this.clickEvent.emit(event);
        }
    }

}
