import { Component, computed, effect, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BaseInputComponent } from '../base-input.component';
import { NgTemplateOutlet } from '@angular/common';
import { fb } from '../../../../modules/shared/shared.global-service';

export const radioInputImports = [
    ReactiveFormsModule,
    RadioButtonModule,
    NgTemplateOutlet,
];

@Component({
    selector: 'app-radio-input',
    templateUrl: './radio-input.component.html',
    styleUrls: ['radio-input.component.scss'],
    standalone: true,
    imports: radioInputImports,
})
export class RadioInputComponent extends BaseInputComponent<any> {

    public override id = input<string>('radio');
    public override form = input<FormGroup>(fb.group({ [this.id()]: fb.control(null) }));

    public staticOptions = input<any[]>([]);

    public layout = input<'horizontal' | 'vertical'>('horizontal');
    public columns = input<number>(1);
    public labelKey = input<string>('name');
    public valueKey = input<string>('value');

    public autoSelectFirst = input<boolean>(false);

    protected colsCssClass = computed(() => {
        return this.layout() === 'horizontal' && this.columns() === 1 ? 'flex gap' : `input-grid-gaps grid-${this.columns()}col`;
    });

    protected translatedStaticOptions = computed(() => {
        return this.staticOptions().map((it) => {
            return {
                [this.valueKey()]: it[this.valueKey()],
                [this.labelKey()]: this.translate.instant(this.prefix() ? `${this.prefix()}${it[this.labelKey()]}` : it[this.labelKey()]),
            };
        });
    });

    protected dividedItems = computed(() => {
        const result = [];

        const opts = [...this.translatedStaticOptions() || []];
        const columns = this.columns();

        if (this.layout() === 'horizontal' || columns === 1) {
            return [opts];
        }

        for (let i = columns; i > 0; i--) {
            result.push(opts.splice(0, Math.ceil(opts.length / i)));
        }

        return result;
    });

    constructor() {
        super();

        effect(() => {
            if (this.autoSelectFirst() && (!this.form().value[this.id()] || this.form().value[this.id()].length === 0)) {
                if (this.valueKey) {
                    this.form().patchValue({
                        [this.id()]: this.staticOptions()?.[0]?.[this.valueKey()] || null,
                    });
                } else {
                    this.form().patchValue({
                        [this.id()]: this.staticOptions()?.[0] || null,
                    });
                }
            }
        });
    }
}
