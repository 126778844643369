import {
    Component,
    computed,
    effect,
    EventEmitter,
    HostBinding,
    input,
    OnChanges,
    Output,
    signal,
} from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass, NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'app-icon',
    templateUrl: 'icon.component.html',
    styleUrl: 'icon.component.scss',
    standalone: true,
    imports: [
        SvgIconComponent,
        NgIf,
        NgClass,
        NgStyle,
    ],
})
export class IconComponent implements OnChanges {

    // So far signal output has no "observed" prop - https://github.com/angular/angular/issues/54837
    // eslint-disable-next-line
    @Output() public onClick = new EventEmitter();

    public icon = input<string>();
    public styleClass = input<string>('');

    public isPrimeIcon = computed(() => {
        return this.icon()?.startsWith('pi-') || false;
    });

    @HostBinding('class.display-none') protected displayNone = true;

    protected isObserved = signal(false);

    constructor() {
        effect(() => {
            this.displayNone = (this.icon()?.length || 0) <= 0;
        });
    }

    public ngOnChanges(): void {
        this.isObserved.set(this.onClick.observed);
    }

    protected clicked(event: Event): void {
        if (!this.isObserved()) return;

        event.stopPropagation();
        this.onClick.emit(event);
    }

}
