import { Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { fb } from '../../../modules/shared/shared.global-service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-base-input',
    template: '',
    standalone: true,
})
export abstract class BaseInputComponent<T> {
    public translate = inject(TranslateService);

    public id = input<string>('input');
    public form = input<FormGroup>(fb.group({ [this.id()]: fb.control<T | null>(null) }));

    /**
     * Overrides prefix + 'fields.' + id as a label
     */
    public label = input<string>();
    public prefix = input<string>();
    public showLabel = input<boolean>(true);

    public disabled = input<boolean>(false);
    public readonly = input<boolean>(false);

    public selectedValue = output<T | undefined>();

    public lbl = computed(() => {
        if (this.showLabel() && !this.label()) {
            if (this.prefix()) {
                return `${this.prefix()}fields.${this.id()}`;
            }

            return `inputs.${this.id()}`;
        }

        return this.label() || '';
    });

    protected required = signal<boolean>(false);
    protected formValueChange = toSignal<T>(this.form().controls[this.id()].valueChanges);

    protected constructor() {
        effect(() => {
            if (this.disabled()) {
                this.form().controls[this.id()].disable();
            } else {
                this.form().controls[this.id()].enable();
            }
        });

        effect(() => {
            this.required.set(this.form()?.get(this.id())?.hasValidator(Validators.required) || false);
        }, { allowSignalWrites: true });

        effect(() => {
            this.selectedValue.emit(this.formValueChange());
        });

    }
}