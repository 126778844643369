import { computed, effect, Injectable, Signal, signal } from '@angular/core';
import { localStorageOrganizationIdKey } from '../utils/local-storage.utils';
import { ServiceGridItemObject } from '@it2go/types';

@Injectable({
    providedIn: 'root',
})
export class OrganizationGlobalStore {

    public selectedId = signal<number>(
        Number(localStorage.getItem(localStorageOrganizationIdKey) || 0),
    );
    public services = signal<ServiceGridItemObject[]>([]);

    constructor() {
        effect(() => {
            localStorage.setItem(localStorageOrganizationIdKey, String(this.selectedId()));
        });
    }

    public getServiceById(id: Signal<number>) {
        return computed(() => {
            return this.services().find((it: ServiceGridItemObject) => it.id === id());
        });
    }

}
