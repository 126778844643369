export const formFrag = `
    id
    name
    description
    abbreviation
    uniqueIdentifier
    createdAt
`;

export const folderFormOnlyFrag = `
    id
    folderId
    formId
    name
    comment
    active
    createdAt
`;

export const folderFormFrag = `
    id
    folderId
    formId
    name
    comment
    active
    createdAt
    form {
        ${formFrag}
    }
`;

export const formSettingsFrag = `
    id
    name
    description
    abbreviation
    uniqueIdentifier
    enabledInService
`;
