import { addressFrag } from './common.types';

export const workplaceFrag = `
    id
    name
    address {
        ${addressFrag}
    }
`;

export const serviceFrag = `
    id
    expertise {
        code
        name
    }
    color
    name
    organizationId
    serviceTypeId
    address {
        ${addressFrag}
    }
    workplaces {
        ${workplaceFrag}
    }
`;

export const serviceGridItemFrag = `
    id
    name
    color
    expertise {
        code
        name
    }
    workplaces {
        id
        name
        dateFrom
        dateTo
    }
    folderId
    timeRanges {
        order
        active
        from
        to
    }
    serviceSettings {
        id
        key
        data
        serviceId
    }
`;
