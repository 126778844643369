import { addressFrag } from './common.types';

export const ePrescriptionMedicationFrag = `
    id
    code
    ePrescriptionId
    amount
    dosing
    paymentByEmployer
    pCheck
    rCheck
    doctorId
`;

export const ePrescriptionFrag = `
    id
    issuedAt
    validTo
    address {
        ${addressFrag}
    }
    note
    suklId
    workId
    status
    doctorId
`;

export const ePrescriptionWithMedicationsFrag = `
    ${ePrescriptionFrag}
    medications {
        ${ePrescriptionMedicationFrag}
    }
`;

export const ePrescriptionFullFrag = `
    ${ePrescriptionFrag}
    doctor {
        titleBeforeName
        name
        surname
        titleAfterName
        email
        icpOfDoctor
    }
    medications {
        ${ePrescriptionMedicationFrag}
    }
    work {
        id
        workType
        dateFrom
    }
`;
