import { Component, contentChild, effect, inject, input, signal, TemplateRef, untracked } from '@angular/core';
import { FieldErrorComponent } from '../../../../modules/shared/components/field-error/field-error.component';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgTemplateOutlet } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import config from '../../../../config/config';
import { InfiniteScrollStore } from '../../../../modules/shared/store/common/infinite-scroll.store';
import { MultiSelectInputStore } from './multi-select-input-store';
import { BaseInputComponent } from '../base-input.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { fb } from '../../../../modules/shared/shared.global-service';

@Component({
    selector: 'app-multi-select-input',
    templateUrl: 'multi-select-input.component.html',
    styleUrl: 'multi-select-input.component.scss',
    standalone: true,
    providers: [MultiSelectInputStore],
    imports: [
        FieldErrorComponent,
        FloatLabelModule,
        MultiSelectModule,
        NgTemplateOutlet,
        PrimeTemplate,
        ReactiveFormsModule,
        TranslateModule,
    ],
})
export class MultiSelectInputComponent extends BaseInputComponent<any[]> {
    public readonly store = inject(MultiSelectInputStore);

    public override id = input<string>('multiSelect');
    public override form = input<FormGroup>(fb.group({ [this.id()]: fb.control([]) }));

    public labelKey = input<string>('name');
    public valueKey = input<string>('value');
    public dataKey = input<string | null>(null);
    public idField = input<string>('id');

    public appendTo = input<string>('body');
    public display = input<string | 'comma' | 'chip'>('chip');

    public showClear = input<boolean>(true);

    public filterBy = input<string | null>(null);
    public staticOptions = input<any[]>([]);

    // TODO group

    public infiniteScroll = input<InfiniteScrollStore<any> | null>(null);

    public itemTemplate = contentChild<TemplateRef<any> | null>('item');
    public selectedItemsTemplate = contentChild<TemplateRef<any> | null>('selectedItems');

    protected searchSubject = new Subject<string>();
    protected searchChange = toSignal<string>(this.searchSubject.pipe(debounceTime(config.debounceTime)));

    private initialized = signal<boolean>(false);

    constructor() {
        super();

        this.fetchItems();

        effect(() => {
            const term = this.searchChange();
            this.fetchItems(term);
        });

        effect(() => {
            const changes = this.formValueChange() || [];
            if (changes.length && !this.initialized()) {
                this.infiniteScroll()?.prefetchItems([[{
                    column: this.idField(),
                    operator: 'IN',
                    value: changes,
                }]], this.idField());
                untracked(() => {
                    this.initialized.set(true);
                });
            }
        });
    }

    public removeItem(event: Event, item: any): void {
        event.stopPropagation();
        this.form().patchValue({
            [this.id()]: this.form().value[this.id()].filter((it: any) => it !== item[this.valueKey()]),
        });
    }

    protected filter(search: string): void {
        this.searchSubject.next(search);
    }

    protected fetchItems(search: string | null = null): void {
        const scroll = this.infiniteScroll();
        if (!scroll) return;

        scroll.changeSearch(search);
    }

}
