import { effect, inject, Injectable, OnDestroy, signal, WritableSignal } from '@angular/core';
import { globalStore, gqlApi } from '../../shared.global-service';
import { Subscription } from 'rxjs';
import { GqlApi } from '../../../../services/api.gql-service';
import { TableV2Component } from '../../components/kit/table-v2/table-v2.component';
import { globalSuccess } from '../../../../store/global/global.actions';
import { RefreshOnAny } from './component-store.factory.base';
import { selectLastAction } from '../../../../store/global/global.selectors';
import { GlobalStore } from '../../../../store/global.global-store';

export type ApiType<T> = {
    [Key in keyof T]: T[Key];
};

export type ApiNames<T> = {
    [Key in keyof T]: Key;
};

export type DataType<T> = {
    // eslint-disable-next-line
    // @ts-ignore
    [Key in keyof T]: WritableSignal<Awaited<ReturnType<T[Key]>>>;
};

@Injectable()
export abstract class ComponentStoreV2Factory implements OnDestroy {

    public readonly globalGlobalStore = inject(GlobalStore);

    public readonly api: ApiType<GqlApi> = {} as ApiType<GqlApi>;
    public readonly data = {} as DataType<GqlApi>;

    protected readonly store = globalStore;
    protected subs: Subscription[] = [];
    protected tableRefreshers: { grid: TableV2Component, actions: string[] }[] = [];
    protected callbacks: { callback: CallableFunction, actions: string[] }[] = [];

    constructor() {
        this.initialize();
    }

    /**
     * @Deprecated()
     */
    public refreshGrid(grid: TableV2Component, ...actions: (GqlApi | string)[]) {
        this.tableRefreshers.push({ grid, actions: actions as string[] });
    }

    /**
     * @Deprecated()
     */
    public ngOnDestroy() {
        this.subs.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @Deprecated()
     */
    public doOnActions(refreshCallback: CallableFunction, ...actions: RefreshOnAny[]) {
        this.subs.push(
            this.store.select(selectLastAction).subscribe((lastAction) => {
                if (actions.includes(lastAction.key)) {
                    refreshCallback(lastAction.data);
                }
            }),
        );
    }

    public onSuccess(refreshCallback: CallableFunction, ...actions: (ApiNames<GqlApi> | string)[]) {
        effect(() => {
            const lastAction = this.globalGlobalStore.apiSuccess();
            if (actions.includes(lastAction?.key || '')) {
                refreshCallback(lastAction?.data);
            }
        });
    }

    private initialize(): void {
        const ignore = ['constructor', 'injectService'];
        const methods = Object.getOwnPropertyNames(Object.getPrototypeOf(gqlApi));
        methods.forEach((method) => {
            if (!ignore.includes(method)) {
                (this.api as any)[method] = async (...args: any[]) => {
                    const res = await (gqlApi as any)[method](...args);
                    (this.data as any)[method].set(res);
                    this.callDependants(method);
                    /** @Deprecated() */
                    this.store.dispatch(globalSuccess({ input: { key: method, data: res } }));
                    this.globalGlobalStore.apiSuccess.set({ key: method, data: res });

                    return res;
                };
                (this.data as any)[method] = signal(null);
            }
        });
    }

    private callDependants(action: string): void {
        this.tableRefreshers.forEach((it) => {
            if (it.actions.includes(action)) {
                it.grid?.refreshData(true);
            }
        });
    }

}
