import { employmentGridFrag } from './user.types';

export const profileFrag = `
    id
    auth2fa
    username
    roleName
    employments {
        ${employmentGridFrag}
        serviceSettings {
            key
            data
            serviceId
        }
    }
`;
