<form [formGroup]="form()">
    @if (infiniteScroll()?.isLast() === false) {
        <div class="mb-2 text-danger"> {{ 'common.tooManyResults' | translate }}</div>
    }

    <p-floatLabel>
        <p-multiSelect
            [options]="infiniteScroll()?.items() || staticOptions()"
            [formControlName]="id()"
            [inputId]="id()"
            [optionLabel]="labelKey()"
            [optionValue]="valueKey()"
            [display]="display()"
            [appendTo]="appendTo()"
            [showClear]="showClear()"
            [dataKey]="dataKey()"
            [emptyFilterMessage]="'common.noResults' | translate"
            (onFilter)="filter($event.filter)"
            [filterBy]="filterBy()"
            [placeholder]="lbl() | translate"
        >
            @if (itemTemplate()) {
                <ng-template pTemplate="item" let-data>
                    <ng-container *ngTemplateOutlet="itemTemplate(); context: { $implicit: data }"></ng-container>
                </ng-template>
            }

            @if (selectedItemsTemplate()) {
                <ng-template pTemplate="selectedItems" let-data>
                    <ng-container
                        *ngTemplateOutlet="selectedItemsTemplate(); context: { $implicit: data }"></ng-container>
                </ng-template>
            }
        </p-multiSelect>
        <label [for]="id()">{{ lbl() | translate }}</label>
    </p-floatLabel>
</form>
<app-field-error [form]="form()" [id]="id()"></app-field-error>

<ng-template #placeholderTemplate>
    {{ lbl() | translate }}
</ng-template>
