export type CommonServiceSettingsType = {
    useCard: boolean,
    customCardName: string | null,
    shareWithOtherServices: number[],
}

export const COMMON_SERVICE_SETTINGS_DEFAULT: CommonServiceSettingsType = {
    useCard: true,
    customCardName: null,
    shareWithOtherServices: [],
};

export const SERVICE_SETTINGS_CONTACTS_TYPE_KEY = 'service_settings_contacts_type';

export const SERVICE_SETTINGS_CARE_PLAN_TYPE_KEY = 'service_settings_care_plan_type';

export const SERVICE_SETTINGS_EPICRISIS_TYPE_KEY = 'service_settings_epicrisis_type';

export const SERVICE_SETTINGS_SPECIALIST_RECORD_TYPE_KEY = 'service_settings_specialist_record_type';

export const SERVICE_SETTINGS_SCHEDULER_TYPE_KEY = 'service_settings_scheduler_type';

export const SERVICE_SETTINGS_EPRESCRIPTIONS_TYPE_KEY = 'service_settings_eprescriptions_type';

export const SERVICE_SETTINGS_SICK_NOTES_TYPE_KEY = 'service_settings_sick_notes_type';