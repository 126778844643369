import { Component, effect, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { BaseInputComponent } from '../base-input.component';
import { fb } from '../../../../modules/shared/shared.global-service';

@Component({
    selector: 'app-check-input',
    templateUrl: 'check-input.component.html',
    styleUrls: ['check-input.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CheckboxModule,
        TranslateModule,
    ],
})
export class CheckInputComponent extends BaseInputComponent<boolean> {
    public selected = input<boolean>(false);

    public override id = input<string>('check');
    public override form = input<FormGroup>(fb.group({ [this.id()]: fb.control(this.selected()) }));

    public labelBoldPrefix = input<string>();

    constructor() {
        super();
        effect(() => {
            this.form().patchValue({
                [this.id()]: this.selected(),
            });
        });
    }

    check(val: boolean) {
        this.selectedValue.emit(val as boolean);
    }
}
