import { Injectable, signal } from '@angular/core';
import { OrganizationGlobalStore } from './organization.global-store';
import { LoggedUserGlobalStore } from './logged-user.global-store';

@Injectable({
    providedIn: 'root',
})
export class GlobalStore {

    public apiSuccess = signal<{ key: string, data: any } | null>(null);

    constructor(
        public readonly organizationStore: OrganizationGlobalStore,
        public readonly loggedUserStore: LoggedUserGlobalStore,
    ) {
    }

}
